import { I18nextProvider } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import Manifest from '../components/forms/Manifest';
import SkipForm from '../components/forms/SkipForm';
import ConfirmRoute from '../components/templates/ConfirmRoute';
import DriverDashboard from '../components/templates/Dashboards/DriverDashboard';
import OperatorDashboard from '../components/templates/Dashboards/OperatorDashboard';
import OperatorDeviceDashboard from '../components/templates/Dashboards/OperatorDeviceDashboard';
import FinishRoute from '../components/templates/FinishRoute';
import FinishRouteTruckMileage from '../components/templates/FinishRouteTruckMileage';
import FirstStop from '../components/templates/FirstStop';
import Logout from '../components/templates/Logout';
import Profile from '../components/templates/Profile';
import Receipt from '../components/templates/Receipt';
import StartQR from '../components/templates/StartQR';
import StartRoute from '../components/templates/StartRoute';
import StartRouteManual from '../components/templates/StartRouteManual';
import UpdateInfo from '../components/templates/UpdateInfo';
import UpdatePin from '../components/templates/UpdatePin';
import DropoffForm from '../components/templates/VendorDropoffs/DropoffForm';
import LocationStep from '../components/templates/VendorDropoffs/LocationStep';
import OperatorStep from '../components/templates/VendorDropoffs/OperatorStep';
import { ROLES } from '../config/permissions';
import { usePermissions } from '../hooks/usePermission';
import i18n from '../i18n';

export const AppRouting = () => {
  const { checkRole, hasUserAuthority } = usePermissions();
  return (
    <>
      {hasUserAuthority() && (
        <I18nextProvider i18n={i18n}>
          <Routes>
            <Route path="/profile" element={<Profile />} />
            {checkRole(ROLES.DRIVER) && (
              <>
                <Route path="/" element={<DriverDashboard />} />
                <Route path="/start" element={<StartRoute />} />
                <Route path="/dash" element={<DriverDashboard />} />
                <Route path="/finishroute" element={<FinishRoute />} />
                <Route
                  path="/finishroutecheckmileage"
                  element={<FinishRouteTruckMileage />}
                />
                <Route path="/startqr" element={<StartQR />} />
                <Route path="/manifest" element={<Manifest />} />
                <Route
                  path="/startroutemanual"
                  element={<StartRouteManual />}
                />
                <Route path="/updateinfo" element={<UpdateInfo />} />
                <Route path="/firststop" element={<FirstStop />} />
                <Route path="/skipstop" element={<SkipForm />} />
                <Route path="/receipt" element={<Receipt />} />
                <Route path="/confirmRoute" element={<ConfirmRoute />} />
              </>
            )}
            {checkRole(ROLES.OPERATOR) && (
              <>
                <Route path="/" element={<OperatorDashboard />} />
                <Route path="/dash" element={<OperatorDashboard />} />
                <Route path="/update-pin" element={<UpdatePin />} />
              </>
            )}
            {checkRole(ROLES.OPERATOR_DEVICE) && (
              <>
                <Route path="/" element={<OperatorDeviceDashboard />} />
                <Route path="/dash" element={<OperatorDeviceDashboard />} />
                <Route path="/vendor-dropoff-1" element={<LocationStep />} />
                <Route path="/vendor-dropoff-2" element={<OperatorStep />} />
                <Route path="/vendor-dropoff-3" element={<DropoffForm />} />
              </>
            )}
            {!checkRole(ROLES.DRIVER) &&
              !checkRole(ROLES.OPERATOR) &&
              !checkRole(ROLES.OPERATOR_DEVICE) && (
                <Route path="*" element={<Logout />} />
              )}
          </Routes>
        </I18nextProvider>
      )}
    </>
  );
};
