import React from 'react';

interface LoadingProps {
  size: number;
}

export const SmallLoader: React.FC<LoadingProps> = ({ size }) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div
        style={{ width: `${size}px`, height: `${size}px` }}
        className="animate-spin"
      >
        <div
          className="h-full w-full rounded-[50%] border-4
        border-b-green-800 border-t-green-900"
        ></div>
      </div>
    </div>
  );
};
