import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { ReactComponent as CloseIcon } from '../../icons/close-square.svg';
import { ReactComponent as DriverIcon } from '../../icons/user.svg';
import { CREATE_VENDOR_DRIVER } from '../../services/graphql/dropoffs/dropoffsMutations';
import { PHONE_REGEX } from '../../utils/RegexValidation';
import FormButton from '../atoms/FormButton';
import Input from '../atoms/Inputs/Input';

type TForm = {
  name: string;
  phone: string;
};

const AddDriverModalForm = ({
  isOpen,
  setIsOpen,
  vendorId,
  onCreated,
}: {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  vendorId: string;
  onCreated: (id: string) => void;
}) => {
  const { control, reset, handleSubmit } = useForm<TForm>();
  const { t } = useTranslation();
  const [createDriver] = useMutation(CREATE_VENDOR_DRIVER);

  const onSubmit = (data: TForm) => {
    if (!vendorId) return;
    createDriver({
      variables: {
        vendor_id: vendorId,
        name: data.name,
        phone: data.phone,
      },
      onCompleted: (res) => {
        reset({});
        onCreated(res.createVendorDriver.data);
      },
    });
  };

  const closeModal = () => {
    reset();
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root') || undefined}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          maxWidth: '470px',
          height: 'min-content',
          margin: 'auto',
          borderRadius: '20px',
        },
      }}
      contentLabel="Alert"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex h-full flex-col justify-between gap-y-10 p-2"
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-x-2">
            <DriverIcon />
            <span>{t('addDriverForm.title')}</span>
          </div>
          <div className="flex justify-end ">
            <button onClick={closeModal}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-y-6">
          <Input
            label={t('addDriverForm.full-name')}
            control={control}
            defaultValue=""
            inputField="name"
            type="text"
            validations={{
              required: t('global.required'),
            }}
          />
          <Input
            label={t('addDriverForm.phone')}
            control={control}
            defaultValue=""
            inputField="phone"
            type="text"
            validations={{
              required: t('global.required'),
              pattern: {
                value: PHONE_REGEX,
                message: t('global.phone-error'),
              },
            }}
          />
        </div>
        <div className="mb-2 flex flex-row justify-center gap-x-3">
          <FormButton
            id="cancelAddDriver"
            icon={''}
            label={t('addDriverForm.cancel')}
            color="white"
            textColor="text-ReyesGreen"
            type="button"
            reverse
            width="auto"
            onClick={closeModal}
          />
          <FormButton
            icon={''}
            type="submit"
            id="createDriver"
            label={t('addDriverForm.create')}
            color="ReyesGreen"
            reverse
            width="auto"
          />
        </div>
      </form>
    </Modal>
  );
};

export default AddDriverModalForm;
