import { useLazyQuery, useMutation } from '@apollo/client';
import { t } from 'i18next';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { ReactComponent as SuccessIcon } from '../../icons/success.svg';
import { UPDATE_USER } from '../../services/graphql/users/users-mutations';
import { CHECK_USER_PIN } from '../../services/graphql/users/users-querys';
import { driverStateAtom } from '../../services/recoil/atoms/driverState';
import Button from '../atoms/Button';
import Title from '../atoms/Title';
import PinKeyboard from '../molecules/PinKeyboard';
import Layout from './Layout';

const UpdatePin = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(driverStateAtom);
  const [step, setStep] = useState<1 | 2 | 3 | 4>(
    !userInfo?.hasOperatorPin ? 2 : 1,
  );
  const [error, setError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');

  const [checkUserPin] = useLazyQuery(CHECK_USER_PIN, {
    fetchPolicy: 'network-only',
  });
  const [updateUser] = useMutation(UPDATE_USER);

  const handleDelete = () => {
    setPassword(password.slice(0, -1));
  };

  const handleClickKeyboard = (keyboard: string) => {
    if (password.length === 4) return;
    const updatedPassword = password.concat(keyboard);
    setPassword(updatedPassword);
    if (updatedPassword.length === 4) {
      setError('');
      if (step === 1 && userInfo) {
        checkUserPin({
          variables: {
            userId: userInfo.id,
            operatorPin: updatedPassword,
          },
          onCompleted: (res) => {
            if (res.checkUserPin.data) {
              setStep(2);
              setPassword('');
            } else {
              setError(t('updatePin.wrong-pin'));
              setPassword('');
            }
          },
          onError: () => {
            setError(t('updatePin.error-check-pin'));
            setPassword('');
          },
        });
      } else if (step === 2) {
        setStep(3);
        setNewPassword(updatedPassword);
        setPassword('');
      } else if (step === 3) {
        if (updatedPassword !== newPassword) {
          setError(t('updatePin.pins-do-not-match'));
          setPassword('');
        } else {
          updateUser({
            variables: {
              key: 'operator_pin',
              userid: userInfo?.id,
              value: updatedPassword,
            },
            onCompleted: () => {
              setStep(4);
              if (!userInfo?.hasOperatorPin) {
                setUserInfo({
                  ...userInfo!,
                  hasOperatorPin: true,
                });
              }
            },
          });
        }
      }
    }
  };

  const title1 = useMemo(() => {
    if (step === 1) return t('updatePin.current');
    if (step === 2) return t('updatePin.new-pin');
    if (step === 3) return t('updatePin.confirm-new-pin');
    return t('updatePin.current');
  }, [step]);

  const title2 = useMemo(() => {
    if (step === 1) return t('updatePin.current-subtitle');
    if (step === 2) return t('updatePin.new-pin-subtitle');
    if (step === 3) return t('updatePin.confirm-new-pin-subtitle');
    return t('updatePin.current-subtitle');
  }, [step]);

  return (
    <Layout>
      <div id="updatePin" className="flex w-full items-center justify-between">
        <div>
          <Title text={t('updatePin.title')} />
        </div>
        {step !== 4 && (
          <div className="mb-8">
            <button
              className="text-ReyesGreenLight"
              onClick={() => navigate('/dash')}
            >
              {t('updatePin.cancel')}
            </button>
          </div>
        )}
      </div>
      {step !== 4 ? (
        <PinKeyboard
          title1={title1}
          title2={title2}
          handleClickKeyboard={handleClickKeyboard}
          password={password}
          error={error}
          handleDelete={handleDelete}
        />
      ) : (
        <div className="flex min-h-[50vh] flex-col items-center justify-center gap-y-10">
          <div className="flex flex-col items-center gap-y-2">
            <SuccessIcon />
            <div className="">{t('updatePin.success')}</div>
          </div>
          <Button
            justify="center"
            id="finishOnRouteButton"
            icon={''}
            label={t('updatePin.back-dashboard')}
            color="ReyesGreen"
            reverse
            width="[90vw]"
            onClick={() => navigate('/dash')}
          />
        </div>
      )}
    </Layout>
  );
};

export default UpdatePin;
