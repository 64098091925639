import { useAuth0 } from '@auth0/auth0-react';
import { t } from 'i18next';
import { useState } from 'react';
import Modal from 'react-modal';

import { ReactComponent as LogOutIcon } from '../../icons/logout.svg';
import Popup from '../molecules/Popup';

const Logout = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { logout } = useAuth0();
  const logoutRedirect = () => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };
  const iconColor = '#2E4347';
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <div className="m-auto flex h-screen items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          id="logoutButton"
          className=" 
        mt-2 inline-flex h-12 w-full max-w-[300px] items-center justify-start gap-4 rounded-xl bg-ReyesGrey px-2.5 py-2 text-center text-lg font-black text-white shadow-md hover:bg-ReyesRed"
        >
          <p>{t('profileform.logout')}</p>
          <LogOutIcon />
          <div className="relative h-6 w-6" />
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            maxWidth: '310px',
            maxHeight: '320px',
            margin: 'auto',
            borderRadius: '20px',
          },
        }}
        contentLabel="Alert"
      >
        <Popup
          closeModal={closeModal}
          openModal={openModal}
          iconColor={iconColor}
          buttonFunction={logoutRedirect}
          idConfirm="logoutConfirm"
          idCancel="logoutCancel"
          buttonLabel={t('profileform.logout')}
          alert={t('profileform.popuplabel')}
          message={t('profileform.popupmssg')}
        />
      </Modal>
    </>
  );
};

export default Logout;
