/* eslint-disable unused-imports/no-unused-vars */
import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ReactComponent as NextIcon } from '../../../icons/arrowcircleright.svg';
import { ReactComponent as CloseIcon } from '../../../icons/close-square.svg';
import { ReactComponent as LocationIcon } from '../../../icons/location-tick.svg';
import { ReactComponent as LockIcon } from '../../../icons/lock.svg';
import {
  CHECK_USER_PIN,
  GET_OPERATORS,
} from '../../../services/graphql/users/users-querys';
import { driverStateAtom } from '../../../services/recoil/atoms/driverState';
import { dropoffSelectedOptionsAtom } from '../../../services/recoil/atoms/dropoffSelectedOptions';
import type { GetUsersResponse, User } from '../../../types/users';
import BigButtonIcon from '../../atoms/BigIconButton';
import Button from '../../atoms/Button';
import Title from '../../atoms/Title';
import PinKeyboard from '../../molecules/PinKeyboard';
import StatusBar from '../../molecules/StatusBar';
import Layout from '../Layout';

function OperatorStep() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userInfo = useRecoilValue(driverStateAtom);
  const [password, setPassword] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [checkUserPin] = useLazyQuery(CHECK_USER_PIN, {
    fetchPolicy: 'network-only',
  });

  const { data } = useQuery<GetUsersResponse>(GET_OPERATORS);
  const [selectedOptions, setSelectedOptions] = useRecoilState(
    dropoffSelectedOptionsAtom,
  );

  const onSelect = (u: User) => {
    setSelectedOptions({
      ...selectedOptions,
      registered_by: u.id,
      registered: u,
    });
  };

  const onPassword = (pass: string) => {
    if (!userInfo) return;
    checkUserPin({
      variables: {
        userId: selectedOptions.registered_by,
        operatorPin: pass,
      },
      onCompleted: (res) => {
        if (res.checkUserPin.data) {
          navigate('/vendor-dropoff-3');
        } else {
          setError(t('updatePin.wrong-pin'));
          setPassword('');
        }
      },
      onError: () => {
        setError(t('updatePin.error-check-pin'));
      },
    });
  };

  const onKeyboardClick = (key: string) => {
    const newPassword = password.concat(key);
    setPassword(newPassword);
    if (newPassword.length === 4) onPassword(newPassword);
  };

  useEffect(() => {
    if (selectedOptions && selectedOptions.location_id === '')
      navigate('/vendor-dropoff-1');
  }, [selectedOptions]);

  const handleDelete = () => {
    setPassword(password.slice(0, -1));
  };

  return (
    <>
      <Layout>
        <div className="">
          <span>
            <Title text={t('dropoff.title')} />
            <div className="space-y-2">
              <StatusBar
                text={[
                  t('dropoff.step1'),
                  t('dropoff.step2'),
                  t('dropoff.step3'),
                ]}
                active={[false, true, false]}
                done={[true, false, false]}
                justifyStart={true}
              />
            </div>
          </span>
          <div className="mx-auto grid w-full max-w-[900px] grid-cols-2 gap-2 md:grid-cols-3">
            {data?.getUsers.data.map((u) => (
              <BigButtonIcon
                key={u.id}
                icon={<LocationIcon />}
                label={u.name}
                onClick={() => onSelect(u)}
                selected={u.id === selectedOptions.registered_by}
              />
            ))}
          </div>
          <Button
            justify="end"
            id="finishOnRouteButton"
            icon={<NextIcon />}
            label={t('dropoff.next')}
            color="ReyesGreen"
            reverse
            width="[90vw]"
            disabled={!selectedOptions.registered_by}
            onClick={() => setOpenModal(true)}
          />
          <Modal
            isOpen={openModal}
            onRequestClose={() => setOpenModal(false)}
            appElement={document.getElementById('root') || undefined}
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
              content: {
                maxWidth: '450px',
                maxHeight: '610px',
                margin: 'auto',
                borderRadius: '20px',
              },
            }}
            contentLabel="Alert"
          >
            <button
              onClick={() => setOpenModal(false)}
              className="absolute right-5 top-5"
            >
              <CloseIcon />
            </button>
            <div className="flex h-full w-full flex-col items-center justify-center gap-y-1">
              <p className="text-lg font-bold">{t('dropoff.enter-pin')}</p>
              <div className="">
                <LockIcon />
              </div>
              <PinKeyboard
                error={error}
                modal
                handleClickKeyboard={onKeyboardClick}
                password={password}
                handleDelete={handleDelete}
              />
            </div>
          </Modal>
        </div>
      </Layout>
    </>
  );
}

export default OperatorStep;
