/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { dropoffSelectedOptionsAtom } from '../../../services/recoil/atoms/dropoffSelectedOptions';
import Title from '../../atoms/Title';
import VendorDropoffForm from '../../forms/VendorDropoffs';
import StatusBar from '../../molecules/StatusBar';
import Layout from '../Layout';

function DropoffForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedOptions = useRecoilValue(dropoffSelectedOptionsAtom);
  useEffect(() => {
    if (
      selectedOptions &&
      (selectedOptions.location_id === '' ||
        selectedOptions.registered_by === '')
    )
      navigate('/vendor-dropoff-1');
  }, [selectedOptions]);
  return (
    <Layout>
      <>
        <span>
          <Title text={t('dropoff.title')} />
          <div className="space-y-2">
            <StatusBar
              text={[
                t('dropoff.step1'),
                t('dropoff.step2'),
                t('dropoff.step3'),
              ]}
              active={[false, false, true]}
              done={[true, true, false]}
              justifyStart={true}
            />
          </div>
        </span>
        <VendorDropoffForm />
      </>
    </Layout>
  );
}

export default DropoffForm;
